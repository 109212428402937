import { getDefaultStore } from 'jotai';
import { idParamsAtom } from '../stores';
import { deleteAuthCookie } from './cookieAction';
export var onLogout = function () {
    var store = getDefaultStore();
    var redirectTo = sessionStorage.getItem('anx_oos_rto');
    store.set(idParamsAtom, '');
    deleteAuthCookie('anx_oss_a_');
    deleteAuthCookie('anx_oss_mfv_');
    sessionStorage.removeItem('anx_oos_rto');
    window.location.replace(redirectTo ? redirectTo : '/');
};
