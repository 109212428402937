import { __awaiter, __generator } from "tslib";
import { Constants } from '../utils/Constants';
import { request } from '../utils/request';
export function getUserDetails(token) {
    return __awaiter(this, void 0, void 0, function () {
        var newProfile, options, resp, respContact, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    options = {
                        method: 'GET',
                        headers: {
                            'X-Access-Token': token,
                        },
                    };
                    return [4, request("".concat(Constants.ANEX_API_ENDPOINT, "/api/v1/account/contacts/user-details"), options)];
                case 1:
                    resp = _a.sent();
                    if (!resp) return [3, 3];
                    newProfile = resp;
                    return [4, request("".concat(Constants.ANEX_API_ENDPOINT, "/api/v1/account/contacts"), options)];
                case 2:
                    respContact = _a.sent();
                    if (respContact) {
                        newProfile['preferredMessageType'] = respContact.preferredMessageType;
                        newProfile['contacts'] = respContact.contacts;
                    }
                    _a.label = 3;
                case 3: return [2, newProfile];
                case 4:
                    err_1 = _a.sent();
                    console.warn('Error retrieving additional user details', err_1);
                    return [2, newProfile];
                case 5: return [2];
            }
        });
    });
}
export function getServices(token) {
    return __awaiter(this, void 0, void 0, function () {
        var services, options, resp, servicesItems, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    services = [];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    options = {
                        method: 'GET',
                        headers: {
                            'X-Access-Token': token,
                        },
                    };
                    return [4, request("".concat(Constants.ANEX_API_ENDPOINT, "/api/v1/account/services"), options)];
                case 2:
                    resp = _a.sent();
                    if (resp) {
                        servicesItems = resp.services.sort(function (a, b) {
                            return a.code.localeCompare(b.code);
                        });
                        services = servicesItems;
                    }
                    return [2, services];
                case 3:
                    err_2 = _a.sent();
                    console.warn('Error retrieving account services', err_2);
                    return [2, services];
                case 4: return [2];
            }
        });
    });
}
export function getStatesLookup(token) {
    return __awaiter(this, void 0, void 0, function () {
        var options, statesResp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    options = {
                        method: 'GET',
                        headers: {
                            'X-Access-Token': token,
                        },
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4, request("".concat(Constants.ANEX_API_ENDPOINT, "/api/v1/lookup/address/location-states"), options)];
                case 2:
                    statesResp = _b.sent();
                    if (statesResp) {
                        return [2, statesResp.locationStates];
                    }
                    return [2, null];
                case 3:
                    _a = _b.sent();
                    console.log('there was an error fetching lookups');
                    return [2, null];
                case 4: return [2];
            }
        });
    });
}
export function getUnitTypesLookup(token) {
    return __awaiter(this, void 0, void 0, function () {
        var options, unitTypesResp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    options = {
                        method: 'GET',
                        headers: {
                            'X-Access-Token': token,
                        },
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4, request("".concat(Constants.ANEX_API_ENDPOINT, "/api/v1/lookup/address/unit-types"), options)];
                case 2:
                    unitTypesResp = _b.sent();
                    if (unitTypesResp) {
                        return [2, unitTypesResp.unitTypes];
                    }
                    return [2, null];
                case 3:
                    _a = _b.sent();
                    console.log('there was an error fetching lookups');
                    return [2, null];
                case 4: return [2];
            }
        });
    });
}
export function getLevelTypesLookup(token) {
    return __awaiter(this, void 0, void 0, function () {
        var lookups, options, levelTypesResp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    options = {
                        method: 'GET',
                        headers: {
                            'X-Access-Token': token,
                        },
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4, request("".concat(Constants.ANEX_API_ENDPOINT, "/api/v1/lookup/address/level-types"), options)];
                case 2:
                    levelTypesResp = _b.sent();
                    if (levelTypesResp) {
                        return [2, levelTypesResp.levelTypes];
                    }
                    return [2, null];
                case 3:
                    _a = _b.sent();
                    console.log('there was an error fetching lookups');
                    return [2, null];
                case 4: return [2];
            }
        });
    });
}
export function getStreetTypesLookup(token) {
    return __awaiter(this, void 0, void 0, function () {
        var options, streetTypesResp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    options = {
                        method: 'GET',
                        headers: {
                            'X-Access-Token': token,
                        },
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4, request("".concat(Constants.ANEX_API_ENDPOINT, "/api/v1/lookup/address/street-types"), options)];
                case 2:
                    streetTypesResp = _b.sent();
                    if (streetTypesResp) {
                        return [2, streetTypesResp.streetTypes];
                    }
                    return [2, null];
                case 3:
                    _a = _b.sent();
                    console.log('there was an error fetching lookups');
                    return [2, null];
                case 4: return [2];
            }
        });
    });
}
