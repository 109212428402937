import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import 'core-js/stable';
import App from './pages/App';
var history = createBrowserHistory();
var wholesalerConfig = JSON.parse(process.env.WHOLESALERCONFIG || '');
var theme = wholesalerConfig && wholesalerConfig.theme ? wholesalerConfig.theme : {};
if (process.env.NODE_ENV === 'development') {
    window['ANEX_API_ENDPOINT'] = process.env.ANEX_API_ENDPOINT;
    window['NODE_ENV'] = process.env.NODE_ENV;
    window['BASE_PATH'] = process.env.BASE_PATH;
}
ReactDOM.render(React.createElement(ThemeProvider, { theme: theme },
    React.createElement(Router, { history: history },
        React.createElement(App, null))), document.getElementById('app'));
