import { __assign, __awaiter, __generator } from "tslib";
import { useCallback, useReducer } from 'react';
import { useAtom } from 'jotai';
import { request } from './request';
import { Constants } from './Constants';
import { authAtom } from '../stores';
var fetchInitialState = {
    loading: false,
    error: false,
    success: false,
    data: undefined,
};
var fetchReducer = function (state, action) {
    switch (action.type) {
        case 'loading':
            return __assign(__assign({}, fetchInitialState), { loading: true });
        case 'error':
            return __assign(__assign({}, fetchInitialState), { error: true, data: action.payload });
        case 'success':
            return __assign(__assign({}, fetchInitialState), { success: true, data: action.payload });
        case 'reset':
            return __assign({}, fetchInitialState);
        default:
            return state;
    }
};
var useFetch = function () {
    var auth = useAtom(authAtom)[0];
    var _a = useReducer(fetchReducer, fetchInitialState), state = _a[0], dispatch = _a[1];
    var go = useCallback(function (url, method, payload, contentType, isLogin) { return __awaiter(void 0, void 0, void 0, function () {
        var apiUrl, cons, headers, options, resp, cons, err_1, errData, _a, cons;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch({ type: 'loading' });
                    apiUrl = "".concat(Constants.ANEX_API_ENDPOINT).concat(url);
                    if (Constants.NODE_ENV !== 'production') {
                        cons = {
                            url: url,
                            data: contentType === 'application/json' && payload
                                ? JSON.parse(payload)
                                : payload,
                        };
                        console.info('%cPayload for API', 'color: #E0AC3B', cons);
                    }
                    headers = {};
                    if (!isLogin) {
                        headers['X-Access-Token'] = auth.token;
                    }
                    if (contentType || contentType === 'application/json') {
                        headers['Content-Type'] = 'application/json';
                    }
                    options = {
                        method: method || 'GET',
                        headers: headers,
                        body: payload || undefined,
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 7]);
                    return [4, request(apiUrl, options)];
                case 2:
                    resp = _b.sent();
                    if (Constants.NODE_ENV !== 'production') {
                        cons = {
                            url: url,
                            data: resp,
                        };
                        console.info('%cPayload for API Response', 'color: #E0AC3B', cons);
                    }
                    if (resp) {
                        dispatch({ type: 'success', payload: resp });
                        return [2];
                    }
                    dispatch({ type: 'error', payload: resp });
                    return [3, 7];
                case 3:
                    err_1 = _b.sent();
                    if ((!err_1.response && err_1.message === 'Failed to fetch') ||
                        err_1.response.status === 500) {
                        dispatch({
                            type: 'error',
                            payload: { status: 500, message: 'Server Error' },
                        });
                        return [2];
                    }
                    if (err_1.response.status === 400) {
                        dispatch({
                            type: 'error',
                            payload: { status: 400, message: 'Bad Request' },
                        });
                        return [2];
                    }
                    if (err_1.response.status === 401) {
                        dispatch({
                            type: 'error',
                            payload: { status: 401, message: 'Unauthorized' },
                        });
                        return [2];
                    }
                    if (err_1.response.status === 404) {
                        dispatch({
                            type: 'error',
                            payload: { status: 404, message: 'Not Found' },
                        });
                        return [2];
                    }
                    if (err_1.response.status === 422) {
                        dispatch({
                            type: 'error',
                            payload: { status: 422, message: 'Unprocessable Entity' },
                        });
                        return [2];
                    }
                    if (!err_1.response) return [3, 5];
                    return [4, err_1.response.json()];
                case 4:
                    _a = _b.sent();
                    return [3, 6];
                case 5:
                    _a = err_1;
                    _b.label = 6;
                case 6:
                    errData = _a;
                    if (Constants.NODE_ENV !== 'production') {
                        cons = {
                            url: url,
                            error: errData,
                        };
                        console.info('%cAPI Error', 'color: #E0AC3B', cons);
                    }
                    dispatch({ type: 'error', payload: errData });
                    return [3, 7];
                case 7: return [2];
            }
        });
    }); }, [auth]);
    var fake = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch({ type: 'loading' });
                    return [4, new Promise(function (resolve) { return setTimeout(resolve, 800); })];
                case 1:
                    _a.sent();
                    dispatch({ type: 'success', payload: payload });
                    return [2];
            }
        });
    }); };
    var reset = function () {
        dispatch({ type: 'reset' });
    };
    return [state, { go: go, reset: reset, fake: fake }];
};
export default useFetch;
