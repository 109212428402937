import { __makeTemplateObject } from "tslib";
import styled from 'styled-components';
import { media } from '../../utils';
export var Flex = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: ", ";\n  justify-content: ", ";\n  flex-direction: ", ";\n  gap: ", ";\n  padding: ", ";\n  border-top: 1px solid\n    ", ";\n\n  ", " {\n    flex-direction: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: ", ";\n  justify-content: ", ";\n  flex-direction: ", ";\n  gap: ", ";\n  padding: ", ";\n  border-top: 1px solid\n    ", ";\n\n  ", " {\n    flex-direction: ", ";\n  }\n"])), function (props) { return (props.alignItems ? props.alignItems : 'initial'); }, function (props) {
    return props.justifyContent ? props.justifyContent : 'initial';
}, function (props) {
    return props.mobileDirection
        ? props.mobileDirection
        : props.direction
            ? props.direction
            : 'initial';
}, function (props) { return (props.gap ? props.gap : '0'); }, function (props) { return (props.padding ? props.padding : '0'); }, function (props) { return (props.borderTop ? props.theme.border.color : 'transparent'); }, media.medium, function (props) { return (props.direction ? props.direction : 'initial'); });
var templateObject_1;
