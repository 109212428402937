import { __awaiter, __generator } from "tslib";
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAtom } from 'jotai';
import { handleAuth } from '../stores';
import GlobalStyle from '../utils/GlobalStyle';
import { TokenManager } from '../utils/TokenManager';
import { request } from '../utils/request';
import { Constants } from '../utils/Constants';
import { SessionTimeout } from '../components/elements';
import { PrivateRoute } from './PrivateRoute';
import { RootPage } from './Root';
import { HomePage, OutagesPage, PlanPage, ServiceTestResultPage, ServiceTestsPage, UsagePage, } from './Home';
import { LoginPage } from './Login';
import { EditProfilePage, ProfilePage } from './Profile';
import { BillingPage, PaymentDetailsPage } from './Billing';
import { CreateAFaultPage, MakeAComplaintPage, SupportPage } from './Support';
import withIdParams from './WithIdParams';
export default function App() {
    var _this = this;
    var isAppInitialized = React.useRef(false);
    var _a = useAtom(handleAuth), _ = _a[0], setAuth = _a[1];
    var _b = React.useState(false), show = _b[0], setShow = _b[1];
    React.useEffect(function () {
        if (Constants.WHOLESALER.toLowerCase() === 'oe') {
            appendAnalytics();
        }
        var interval;
        if (!isAppInitialized.current) {
            isAppInitialized.current = true;
            var token = TokenManager.getToken();
            if (TokenManager.validateToken(token)) {
                refreshToken();
                interval = setInterval(function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        refreshToken();
                        return [2];
                    });
                }); }, 10 * 60 * 1000);
            }
        }
        (function () {
            clearInterval(interval);
        });
    }, []);
    var refreshToken = function () { return __awaiter(_this, void 0, void 0, function () {
        var token, resp, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = TokenManager.getToken();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4, request("".concat(Constants.ANEX_API_ENDPOINT, "/api/v1/authentication/refresh-token"), {
                            method: 'GET',
                            headers: {
                                'X-Access-Token': token,
                            },
                        })];
                case 2:
                    resp = _a.sent();
                    if (resp && resp.token) {
                        setAuth({
                            token: resp.token,
                            invalidToken: false,
                            isAuthenticated: true,
                        });
                    }
                    return [3, 4];
                case 3:
                    err_1 = _a.sent();
                    if (err_1 && err_1.response && err_1.response.status === 401) {
                        console.log('timeout');
                        setShow(true);
                    }
                    return [3, 4];
                case 4: return [2];
            }
        });
    }); };
    var appendAnalytics = function () {
        var script = document.createElement('script');
        var url = Constants.NODE_ENV.toLowerCase() !== 'production'
            ? 'https://assets.adobedtm.com/launch-EN3fdba9159ab74025b0142efa5db4d90b-development.min.js'
            : 'https://assets.adobedtm.com/52e6c31223bb/d39c1c648e76/launch-EN2a7c2ef32e9748839b178d294225289f.min.js';
        script.setAttribute('src', url);
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('async', 'true');
        document.head.appendChild(script);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(GlobalStyle, null),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: "/", component: RootPage }),
            React.createElement(Route, { path: "/login", component: LoginPage }),
            React.createElement(PrivateRoute, { exact: true, path: "/home", component: withIdParams(HomePage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/home/usage", component: withIdParams(UsagePage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/home/service-tests", component: withIdParams(ServiceTestsPage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/home/service-tests/:id", component: withIdParams(ServiceTestResultPage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/home/plan", component: withIdParams(PlanPage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/home/outages", component: withIdParams(OutagesPage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/profile", component: withIdParams(ProfilePage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/profile/edit", component: withIdParams(EditProfilePage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/billing", component: withIdParams(BillingPage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/support", component: withIdParams(SupportPage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/support/new-complaint", component: withIdParams(MakeAComplaintPage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/billing", component: withIdParams(BillingPage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/billing/payment-details", component: withIdParams(PaymentDetailsPage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/support", component: withIdParams(SupportPage) }),
            React.createElement(PrivateRoute, { exact: true, path: "/support/report-fault", component: withIdParams(CreateAFaultPage) })),
        React.createElement(SessionTimeout, { show: show })));
}
