import * as React from 'react';
import { LoadingContainer, LoadingWrapper } from './styles';
export function Loading(_a) {
    var position = _a.position, loading = _a.loading, iconWidth = _a.iconWidth, text = _a.text, children = _a.children;
    if (!children) {
        return (React.createElement(LoadingWrapper, { position: position || '', iconWidth: iconWidth ? iconWidth : '' },
            text && (React.createElement("p", { className: "loading-text", style: { marginTop: '-60px', marginBottom: '6px' } }, text)),
            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100", preserveAspectRatio: "xMidYMid" },
                React.createElement("circle", { cx: "50", cy: "50", fill: "none", stroke: "#e15b64", strokeWidth: "5", r: "35", strokeDasharray: "164.93361431346415 56.97787143782138" },
                    React.createElement("animateTransform", { attributeName: "transform", type: "rotate", repeatCount: "indefinite", dur: "1s", values: "0 50 50;360 50 50", keyTimes: "0;1" })))));
    }
    return (React.createElement(LoadingContainer, null,
        loading && (React.createElement(LoadingWrapper, { position: position || '', iconWidth: iconWidth ? iconWidth : '' },
            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100", preserveAspectRatio: "xMidYMid" },
                React.createElement("circle", { cx: "50", cy: "50", fill: "none", stroke: "#e15b64", strokeWidth: "5", r: "35", strokeDasharray: "164.93361431346415 56.97787143782138" },
                    React.createElement("animateTransform", { attributeName: "transform", type: "rotate", repeatCount: "indefinite", dur: "1s", values: "0 50 50;360 50 50", keyTimes: "0;1" }))))),
        children));
}
