import { __makeTemplateObject } from "tslib";
import * as React from 'react';
import styled from 'styled-components';
import { media } from '../../utils/GlobalStyle';
export var LoginMain = styled.main(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-width: 100vw;\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-direction: column;\n  padding-top: 25%;\n  background-color: ", ";\n\n  .logo {\n    max-height: 110px;\n  }\n\n  .card {\n    margin-top: 12px;\n    width: 95%;\n    min-width: 310px;\n    max-width: 350px;\n    text-align: center;\n    font-size: 0.9em;\n  }\n\n  ", " {\n    padding-top: 10%;\n\n    .card {\n      width: 100%;\n      max-width: 420px;\n    }\n  }\n"], ["\n  min-width: 100vw;\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-direction: column;\n  padding-top: 25%;\n  background-color: ", ";\n\n  .logo {\n    max-height: 110px;\n  }\n\n  .card {\n    margin-top: 12px;\n    width: 95%;\n    min-width: 310px;\n    max-width: 350px;\n    text-align: center;\n    font-size: 0.9em;\n  }\n\n  ", " {\n    padding-top: 10%;\n\n    .card {\n      width: 100%;\n      max-width: 420px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.loginBgColor || 'transparent';
}, media.small);
export var Main = styled.main(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  background-color: ", ";\n\n  ", " {\n    padding-left: var(--sidebar-width);\n  }\n"], ["\n  position: relative;\n  background-color: ", ";\n\n  ", " {\n    padding-left: var(--sidebar-width);\n  }\n"])), function (props) { return props.theme.content.bgColor || '#f1f6fb'; }, media.medium);
export var PageWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  // position: relative;\n"], ["\n  // position: relative;\n"])));
export var Spacer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n  height: ", ";\n"], ["\n  display: block;\n  width: 100%;\n  height: ", ";\n"])), function (props) { return (props.height ? props.height : '0'); });
var StyledContentWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 24px 16px calc(65px + 18px);\n  min-height: 100vh;\n\n  ", " {\n    padding-left: 24px;\n    padding-right: 24px;\n    padding-bottom: 24px;\n  }\n"], ["\n  padding: 24px 16px calc(65px + 18px);\n  min-height: 100vh;\n\n  ", " {\n    padding-left: 24px;\n    padding-right: 24px;\n    padding-bottom: 24px;\n  }\n"])), media.medium);
export function ContentWrapper(_a) {
    var children = _a.children;
    var headerHeight = document.getElementById('site-header')
        ? document.getElementById('site-header').offsetHeight
        : 120;
    var _b = React.useState(0), paddingTop = _b[0], setPaddingTop = _b[1];
    React.useEffect(function () {
        if (headerHeight) {
            setPaddingTop(headerHeight + 18);
        }
    }, []);
    return (React.createElement(StyledContentWrapper, { style: { paddingTop: "".concat(paddingTop, "px") } }, children));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
