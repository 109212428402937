import { __makeTemplateObject } from "tslib";
import * as React from 'react';
import styled from 'styled-components';
import { Flex } from './Flex';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 5px 0 12px;\n  width: 100%;\n\n  .labels {\n    font-size: 0.85rem;\n  }\n"], ["\n  margin: 5px 0 12px;\n  width: 100%;\n\n  .labels {\n    font-size: 0.85rem;\n  }\n"])));
var BarWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: #deebf7;\n  border-radius: 6px;\n"], ["\n  background: #deebf7;\n  border-radius: 6px;\n"])));
var BarStatus = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 12px;\n  width: ", ";\n  background: ", ";\n  transition: all 200ms;\n  border-radius: 6px;\n"], ["\n  height: 12px;\n  width: ", ";\n  background: ", ";\n  transition: all 200ms;\n  border-radius: 6px;\n"])), function (props) { return (props.width ? "".concat(props.width, "%") : '0%'); }, function (props) { return props.theme.primaryColor; });
export function ProgressBar(_a) {
    var percent = _a.percent, labelStart = _a.labelStart, labelEnd = _a.labelEnd;
    return (React.createElement(Wrapper, null,
        React.createElement(BarWrapper, null,
            React.createElement(BarStatus, { width: isNaN(percent) ? '0' : percent.toString() })),
        labelStart || labelEnd ? (React.createElement(Flex, { className: "labels", alignItems: "center", justifyContent: "space-between", direction: "row-reverse" },
            React.createElement("span", null, labelEnd),
            React.createElement("span", null, labelStart))) : ('')));
}
var templateObject_1, templateObject_2, templateObject_3;
