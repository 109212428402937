import { __makeTemplateObject } from "tslib";
import * as React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../elements';
import tickIcon from '../assets/icontick.png';
var ModalMessageWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: ", ";\n\n  img {\n    width: 100px;\n  }\n"], ["\n  text-align: ", ";\n\n  img {\n    width: 100px;\n  }\n"])), function (props) { return (props.centerMessage ? 'center' : 'left'); });
export function ModalMessage(_a) {
    var hideIcon = _a.hideIcon, message = _a.message, centerMessage = _a.centerMessage;
    return (React.createElement(ModalMessageWrapper, { centerMessage: centerMessage },
        hideIcon ? '' : React.createElement("img", { src: tickIcon, alt: "Success" }),
        React.createElement(Paragraph, null, message)));
}
var templateObject_1;
