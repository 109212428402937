import { atom } from 'jotai';
var usagesAtom = atom({
    monthly: [],
    yearly: [],
    cdrs: [],
});
export var handleUsage = atom(function (get) { return get(usagesAtom); }, function (get, set, payload) {
    var usages = get(usagesAtom);
    if (payload.type === 'monthly') {
        var exists = usages.monthly.findIndex(function (usage) { return usage.key === payload.key; });
        if (exists > -1) {
            usages.monthly[exists] = payload;
        }
        else {
            usages.monthly.push(payload);
        }
    }
    if (payload.type === 'yearly') {
        var exists = usages.yearly.findIndex(function (usage) { return usage.key === payload.key; });
        if (exists > -1) {
            usages.yearly[exists] = payload;
        }
        else {
            usages.yearly.push(payload);
        }
    }
    if (payload.type === 'cdrs') {
        var exists = usages.cdrs.findIndex(function (usage) { return usage.key === payload.key; });
        if (exists > -1) {
            usages.cdrs[exists] = payload;
        }
        else {
            usages.cdrs.push(payload);
        }
    }
    set(usagesAtom, usages);
});
