import * as React from 'react';
import { Modal } from '../modal';
import { onLogout } from '../../utils/authAction';
import { Button } from './Button';
import { Paragraph } from './Headings';
export function SessionTimeout(_a) {
    var show = _a.show;
    return (React.createElement(Modal, { open: show, hideCloseIcon: true, footer: React.createElement(React.Fragment, null,
            React.createElement(Button, { variant: "brand", onClick: onLogout }, "Ok")) },
        React.createElement(Paragraph, null, "Your session has timed out. Please click Ok to return to login page.")));
}
