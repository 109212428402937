import { __assign, __awaiter, __generator } from "tslib";
import { createJSONStorage } from 'jotai/utils';
import { atom } from 'jotai';
import { TokenManager } from '../utils/TokenManager';
import { getServices, getUserDetails } from './apiCalls';
import { profileAtom } from './profile';
import { servicesAtom } from './services';
import { getCookie, setCookie } from '../utils/cookieAction';
var authInitialValue = {
    token: '',
    invalidToken: false,
    isAuthenticated: false,
};
var storage = createJSONStorage(function () { return sessionStorage; });
var storedValue = JSON.parse(getCookie('anx_oss_a_'));
var authValue = __assign({}, authInitialValue);
if (storedValue) {
    if (TokenManager.validateToken(storedValue.token)) {
        authValue = storedValue;
    }
}
export var authAtom = atom(authValue);
export var handleAuth = atom(function (get) { return get(authAtom); }, function (get, set, payload) { return __awaiter(void 0, void 0, void 0, function () {
    var newProfile, newServices, profileResp, serviceResp;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                newProfile = get(profileAtom);
                newServices = get(servicesAtom);
                set(authAtom, payload);
                setCookie('anx_oss_a_', payload);
                return [4, getUserDetails(payload.token)];
            case 1:
                profileResp = _a.sent();
                if (profileResp) {
                    newProfile = profileResp;
                }
                return [4, getServices(payload.token)];
            case 2:
                serviceResp = _a.sent();
                if (serviceResp) {
                    newServices.list = serviceResp;
                    newServices.currentService = serviceResp[0];
                }
                set(servicesAtom, __assign({}, newServices));
                set(profileAtom, newProfile);
                return [2];
        }
    });
}); });
