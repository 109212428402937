import { __makeTemplateObject } from "tslib";
import styled, { keyframes } from 'styled-components';
var fadeIn = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n"], ["\n  0% {\n    animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n"])));
export var ModalOverlay = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 1040;\n  background-color: rgba(0, 0, 0, 0.3);\n  animation: ", " 0.3s 1 ease-in;\n  animation-fill-mode: forwards;\n  opacity: 0;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 1040;\n  background-color: rgba(0, 0, 0, 0.3);\n  animation: ", " 0.3s 1 ease-in;\n  animation-fill-mode: forwards;\n  opacity: 0;\n"])), fadeIn);
export var ModalWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  /* position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 1050; */\n  width: 100%;\n  height: 100%;\n  overflow-x: hidden;\n  overflow-y: auto;\n  outline: 0;\n  display: flex;\n  align-items: center;\n"], ["\n  /* position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 1050; */\n  width: 100%;\n  height: 100%;\n  overflow-x: hidden;\n  overflow-y: auto;\n  outline: 0;\n  display: flex;\n  align-items: center;\n"])));
var sizes = {
    small: '500px',
    medium: '700px',
    large: '85%',
};
export var ModalBody = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  z-index: 1040;\n  background: ", ";\n  position: relative;\n  margin: auto;\n  border-radius: 5px;\n  max-width: ", ";\n  width: 95%;\n"], ["\n  z-index: 1040;\n  background: ", ";\n  position: relative;\n  margin: auto;\n  border-radius: 5px;\n  max-width: ", ";\n  width: 95%;\n"])), function (props) { return props.theme.body.bgColor; }, function (props) { return sizes[props.size] || sizes['medium']; });
export var ModalHeader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1em;\n  font-size: 1.125em;\n  font-weight: 600;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1em;\n  font-size: 1.125em;\n  font-weight: 600;\n"])));
export var ModalContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 1em;\n  font-size: 0.95em;\n"], ["\n  padding: 1em;\n  font-size: 0.95em;\n"])));
export var ModalFooter = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 1em;\n\n  button {\n    margin-left: 6px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 1em;\n\n  button {\n    margin-left: 6px;\n  }\n"])));
export var ModalClose = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  color: ", ";\n  cursor: pointer;\n  border: none;\n  background: transparent;\n  padding: 5px 5px 0;\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  color: ", ";\n  cursor: pointer;\n  border: none;\n  background: transparent;\n  padding: 5px 5px 0;\n\n  &:hover {\n    color: ", ";\n  }\n"])), function (props) { return props.theme.button.secondary.bgColor; }, function (props) { return props.theme.button.secondary.hover; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
