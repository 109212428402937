import { __makeTemplateObject } from "tslib";
import styled, { css } from 'styled-components';
var blockStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n"], ["\n  display: flex;\n  width: 100%;\n"])));
var sizes = {
    small: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 6px 10px;\n    min-height: 30px;\n    font-size: 0.85em;\n\n    svg {\n      width: 18px;\n    }\n  "], ["\n    padding: 6px 10px;\n    min-height: 30px;\n    font-size: 0.85em;\n\n    svg {\n      width: 18px;\n    }\n  "]))),
    medium: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: 8px 14px;\n    min-height: 40px;\n    font-size: 0.95em;\n\n    svg {\n      width: 22px;\n    }\n  "], ["\n    padding: 8px 14px;\n    min-height: 40px;\n    font-size: 0.95em;\n\n    svg {\n      width: 22px;\n    }\n  "]))),
    large: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 12px 16px;\n    min-height: 50px;\n    font-size: 1.125em;\n\n    svg {\n      width: 24px;\n    }\n  "], ["\n    padding: 12px 16px;\n    min-height: 50px;\n    font-size: 1.125em;\n\n    svg {\n      width: 24px;\n    }\n  "]))),
};
export var Button = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  border-radius: ", ";\n\n  ", ";\n  ", ";\n\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  line-height: 1;\n  text-align: center;\n  border: 0;\n  cursor: pointer;\n  transition: all 0.2s ease-in;\n  margin: ", ";\n\n  &.inactive {\n    cursor: default;\n    pointer-events: none;\n  }\n\n  svg {\n    fill: currentColor;\n  }\n\n  svg:not(:only-child) {\n    margin-right: 4px;\n  }\n\n  &:not(.inactive):hover,\n  &:not(.inactive):focus {\n    background-color: ", ";\n  }\n\n  &:disabled {\n    opacity: 0.65;\n    pointer-events: none;\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  border-radius: ", ";\n\n  ", ";\n  ", ";\n\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  line-height: 1;\n  text-align: center;\n  border: 0;\n  cursor: pointer;\n  transition: all 0.2s ease-in;\n  margin: ", ";\n\n  &.inactive {\n    cursor: default;\n    pointer-events: none;\n  }\n\n  svg {\n    fill: currentColor;\n  }\n\n  svg:not(:only-child) {\n    margin-right: 4px;\n  }\n\n  &:not(.inactive):hover,\n  &:not(.inactive):focus {\n    background-color: ", ";\n  }\n\n  &:disabled {\n    opacity: 0.65;\n    pointer-events: none;\n  }\n"])), function (props) {
    return props.theme.button[props.variant].bgColor || '#1976d2';
}, function (props) { return props.theme.button[props.variant].textColor || '#1976d2'; }, function (props) { return props.theme.border.radius || '6px'; }, function (props) { return props.block && blockStyle; }, function (props) { return (props.size ? sizes[props.size] : sizes['medium']); }, function (props) { return (props.margin ? props.margin : '0px'); }, function (props) {
    return props.theme.button[props.variant].hover || '#1565c0';
});
export var CardBlockButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  border-radius: ", ";\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  background-color: #fff;\n  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),\n    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);\n  padding: 12px;\n  width: 190px;\n  height: 140px;\n  cursor: pointer;\n  border: 0;\n  outline: 0;\n  font-size: 1em;\n  word-break: break-all;\n\n  &.inactive,\n  &:disabled {\n    opacity: 0.65;\n    pointer-events: none;\n  }\n\n  svg {\n    width: 1.125em;\n  }\n\n  &:not(.inactive):focus {\n    border-color: ", ";\n  }\n  &:not(.inactive):hover {\n    background-color: ", ";\n  }\n"], ["\n  color: ", ";\n  border-radius: ", ";\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  background-color: #fff;\n  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),\n    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);\n  padding: 12px;\n  width: 190px;\n  height: 140px;\n  cursor: pointer;\n  border: 0;\n  outline: 0;\n  font-size: 1em;\n  word-break: break-all;\n\n  &.inactive,\n  &:disabled {\n    opacity: 0.65;\n    pointer-events: none;\n  }\n\n  svg {\n    width: 1.125em;\n  }\n\n  &:not(.inactive):focus {\n    border-color: ", ";\n  }\n  &:not(.inactive):hover {\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.body.textColor; }, function (props) { return props.theme.border.radius || '6px'; }, function (props) { return props.theme.button.info.hover; }, function (props) { return props.theme.button.info.bgColor; });
export var CardButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  border-radius: ", ";\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background-color: #fff;\n  cursor: pointer;\n  border: 0;\n  outline: 0;\n  font-size: 1em;\n  padding: 14px 18px;\n  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),\n    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);\n\n  &.inactive,\n  &:disabled {\n    opacity: 0.65;\n    pointer-events: none;\n  }\n\n  svg {\n    width: 1.125em;\n  }\n\n  &:not(.inactive):not(:disabled):focus {\n    border-color: ", ";\n  }\n\n  &:not(.inactive):not(:disabled):hover {\n    background-color: ", ";\n  }\n"], ["\n  color: ", ";\n  border-radius: ", ";\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background-color: #fff;\n  cursor: pointer;\n  border: 0;\n  outline: 0;\n  font-size: 1em;\n  padding: 14px 18px;\n  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),\n    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);\n\n  &.inactive,\n  &:disabled {\n    opacity: 0.65;\n    pointer-events: none;\n  }\n\n  svg {\n    width: 1.125em;\n  }\n\n  &:not(.inactive):not(:disabled):focus {\n    border-color: ", ";\n  }\n\n  &:not(.inactive):not(:disabled):hover {\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.body.textColor; }, function (props) { return props.theme.border.radius || '6px'; }, function (props) { return props.theme.button.info.hover; }, function (props) { return props.theme.button.info.bgColor; });
export var BigButton = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  outline: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  padding: 12px 24px;\n  cursor: pointer;\n  border: 0;\n  outline: 0;\n  font-size: 1em;\n  background-color: transparent;\n  color: #828e97;\n  margin: ", ";\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  outline: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  padding: 12px 24px;\n  cursor: pointer;\n  border: 0;\n  outline: 0;\n  font-size: 1em;\n  background-color: transparent;\n  color: #828e97;\n  margin: ", ";\n\n  &:hover {\n    color: ", ";\n  }\n"])), function (props) { return (props.center ? '0 auto' : '0'); }, function (props) { return props.theme.body.textColor; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
