import * as React from 'react';
import { createPortal } from 'react-dom';
import { ModalBody, ModalClose, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ModalWrapper, } from './styles';
import { CloseOutlineIcon } from '../../components/icons';
import { Button } from '../../components/elements';
var modalRoot = document.getElementById('modals-root');
export function Modal(_a) {
    var open = _a.open, title = _a.title, onClose = _a.onClose, hideCloseIcon = _a.hideCloseIcon, footer = _a.footer, cancelText = _a.cancelText, okText = _a.okText, children = _a.children, onOk = _a.onOk, onCancel = _a.onCancel, size = _a.size, hideCancel = _a.hideCancel;
    return createPortal(open ? (React.createElement(ModalOverlay, null,
        React.createElement(ModalWrapper, null,
            React.createElement(ModalBody, { size: size || 'small' },
                !hideCloseIcon && (React.createElement(ModalClose, { onClick: onClose },
                    React.createElement(CloseOutlineIcon, null))),
                title && React.createElement(ModalHeader, null, title),
                React.createElement(ModalContent, null, children),
                footer && (footer != null || React.isValidElement(footer)) ? (React.createElement(ModalFooter, null, footer)) : (React.createElement(ModalFooter, null,
                    !hideCancel && (React.createElement(Button, { variant: "secondary", onClick: onCancel || onClose || undefined }, cancelText ? cancelText : 'Cancel')),
                    React.createElement(Button, { variant: "primary", onClick: onOk || undefined }, okText ? okText : 'OK'))))))) : (''), modalRoot);
}
