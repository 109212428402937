var Constants = (function () {
    function Constants() {
        this.WHOLESALERSETTINGS =
            JSON.parse(process.env.WHOLESALERCONFIG).settings || {};
        this.THEME = JSON.parse(process.env.WHOLESALERCONFIG).theme || {};
    }
    Object.defineProperty(Constants.prototype, "ANEX_API_ENDPOINT", {
        get: function () {
            return window.ANEX_API_ENDPOINT;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Constants.prototype, "BASE_PATH", {
        get: function () {
            if (window.BASE_PATH === '/' ||
                typeof window.BASE_PATH === 'undefined') {
                return '';
            }
            return window.BASE_PATH;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Constants.prototype, "NODE_ENV", {
        get: function () {
            return window.NODE_ENV;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Constants.prototype, "WHOLESALER", {
        get: function () {
            return this.WHOLESALERSETTINGS.code;
        },
        enumerable: false,
        configurable: true
    });
    return Constants;
}());
var constants = new Constants();
export { constants as Constants };
