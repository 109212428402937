import { __makeTemplateObject } from "tslib";
import styled from 'styled-components';
export var H1 = styled.h1(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0 0 16px;\n  font-size: 2em;\n\n  small {\n    font-weight: 400;\n  }\n"], ["\n  margin: 0 0 16px;\n  font-size: 2em;\n\n  small {\n    font-weight: 400;\n  }\n"])));
export var H2 = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 0 16px;\n  font-size: 1.75em;\n  color: #828e97;\n\n  small {\n    font-weight: 400;\n  }\n"], ["\n  margin: 0 0 16px;\n  font-size: 1.75em;\n  color: #828e97;\n\n  small {\n    font-weight: 400;\n  }\n"])));
export var H3 = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0 0 16px;\n  font-size: 1.5em;\n  color: #828e97;\n\n  small {\n    font-weight: 400;\n  }\n"], ["\n  margin: 0 0 16px;\n  font-size: 1.5em;\n  color: #828e97;\n\n  small {\n    font-weight: 400;\n  }\n"])));
export var H4 = styled.h4(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0 0 16px;\n  font-size: 1.375em;\n  color: #828e97;\n\n  small {\n    font-weight: 400;\n  }\n"], ["\n  margin: 0 0 16px;\n  font-size: 1.375em;\n  color: #828e97;\n\n  small {\n    font-weight: 400;\n  }\n"])));
export var H5 = styled.h5(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0 0 14px;\n  font-size: 1.25em;\n  color: #828e97;\n\n  small {\n    font-weight: 400;\n  }\n"], ["\n  margin: 0 0 14px;\n  font-size: 1.25em;\n  color: #828e97;\n\n  small {\n    font-weight: 400;\n  }\n"])));
export var H6 = styled.h6(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 0 0 14px;\n  font-size: 1em;\n  color: #828e97;\n\n  small {\n    font-weight: 400;\n  }\n"], ["\n  margin: 0 0 14px;\n  font-size: 1em;\n  color: #828e97;\n\n  small {\n    font-weight: 400;\n  }\n"])));
export var Paragraph = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  line-height: 1.2;\n  font-size: 0.95rem;\n\n  .text-muted {\n    color: ", ";\n  }\n\n  a {\n    color: ", ";\n    &:hover {\n      color: ", ";\n    }\n  }\n"], ["\n  line-height: 1.2;\n  font-size: 0.95rem;\n\n  .text-muted {\n    color: ", ";\n  }\n\n  a {\n    color: ", ";\n    &:hover {\n      color: ", ";\n    }\n  }\n"])), function (props) { return props.theme.body.textMuted; }, function (props) { return props.theme.linkColor; }, function (props) { return props.theme.linkHoverColor; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
