import { __awaiter, __generator } from "tslib";
import { atom } from 'jotai';
import { getLevelTypesLookup, getStatesLookup, getStreetTypesLookup, getUnitTypesLookup, } from './apiCalls';
import { TokenManager } from '../utils/TokenManager';
export var lookupInitialValues = {
    unitTypes: null,
    streetTypes: null,
    states: null,
    levelTypes: null,
};
export var lookupAtoms = atom(lookupInitialValues);
export var handleLookupAtoms = atom(function (get) { return get(lookupAtoms); }, function (get, set) { return __awaiter(void 0, void 0, void 0, function () {
    var token, lookups;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                token = TokenManager.getToken();
                _a = {};
                return [4, getUnitTypesLookup(token)];
            case 1:
                _a.unitTypes = _b.sent();
                return [4, getStreetTypesLookup(token)];
            case 2:
                _a.streetTypes = _b.sent();
                return [4, getStatesLookup(token)];
            case 3:
                _a.states = _b.sent();
                return [4, getLevelTypesLookup(token)];
            case 4:
                lookups = (_a.levelTypes = _b.sent(),
                    _a);
                set(lookupAtoms, lookups);
                return [2];
        }
    });
}); });
