import { __awaiter, __extends, __generator } from "tslib";
var ResponseError = (function (_super) {
    __extends(ResponseError, _super);
    function ResponseError(response) {
        var _this = _super.call(this, response.statusText) || this;
        _this.response = response;
        return _this;
    }
    return ResponseError;
}(Error));
export { ResponseError };
function parseJSON(response) {
    if (response.status === 204 || response.status === 205) {
        return { status: response.status };
    }
    var contentType = response.headers.get('content-type');
    if (!contentType && response.status === 200) {
        return { status: response.status, success: true };
    }
    return contentType && contentType.indexOf('application/json') !== -1
        ? response.json()
        : contentType.indexOf('text/plain') !== -1
            ? response.text()
            : { status: response.status, success: true };
}
function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    var error = new ResponseError(response);
    error.response = response;
    throw error;
}
export function request(url, options) {
    return __awaiter(this, void 0, void 0, function () {
        var fetchResponse, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, fetch(url, options)];
                case 1:
                    fetchResponse = _a.sent();
                    response = checkStatus(fetchResponse);
                    return [2, parseJSON(response)];
            }
        });
    });
}
