import dayjs from 'dayjs';
import { Constants } from './Constants';
export var getImageByName = function (name) {
    var code = Constants.WHOLESALER;
    return require("../wholesalers/".concat(code, "/images/").concat(name));
};
export function LightenDarkenColor(col, amt) {
    var usePound = false;
    if (col[0] == '#') {
        col = col.slice(1);
        usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255)
        r = 255;
    else if (r < 0)
        r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255)
        b = 255;
    else if (b < 0)
        b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255)
        g = 255;
    else if (g < 0)
        g = 0;
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}
export function validateEmail(email) {
    return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}
export function displayFormatDate(date, long) {
    if (long === void 0) { long = false; }
    if (!date)
        return '';
    return long
        ? dayjs(date).format('DD-MM-YYYY hh:mm:ss A')
        : dayjs(date).format('DD-MM-YYYY');
}
export function formatBytes(bytes, decimals) {
    if (decimals === void 0) { decimals = 2; }
    if (!+bytes)
        return '0 Bytes';
    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return "".concat(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), " ").concat(sizes[i]);
}
export function titleCase(input) {
    return input.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase(); });
}
export function objectHasValues(givenObject) {
    return Object.keys(givenObject).some(function (field) { return !!givenObject[field]; });
}
export function formatCurrency(cents, hideSymbol) {
    var dollars = (cents / 100).toFixed(2);
    var symbol = hideSymbol ? '' : '$';
    if (dollars !== 'NaN') {
        return cents >= 0 ? "".concat(symbol).concat(dollars) : "".concat(symbol).concat(dollars.slice(1));
    }
    return cents;
}
export function displayAddress(address) {
    return "".concat(address.unitNumber || '').concat(' ', "\n    ").concat(address.streetNumber).concat(' ', "\n    ").concat(address.streetName).concat(' ', "\n    ").concat(address.streetType).concat(' ', "\n    ").concat(address.suburb).concat(' ', "\n    ").concat(address.state).concat(' ', "\n    ").concat(address.postCode).concat(' ');
}
export function validateLandlinePhone(phone) {
    return phone.match(/^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2|4|3|7|8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/gm);
}
export function generateUUID() {
    var template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return template.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0;
        var v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
