import { __makeTemplateObject } from "tslib";
import * as React from 'react';
import styled from 'styled-components';
var ListWrapper = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  list-style: none;\n  margin: 0;\n  padding: 0;\n\n  li {\n    display: flex;\n    align-items: center;\n    justify-content: ", ";\n    gap: 12px;\n    padding: 12px;\n    border-bottom: 1px solid ", ";\n\n    svg {\n      width: 22px;\n      fill: ", ";\n    }\n\n    &:last-child {\n      border-bottom-width: 0;\n    }\n  }\n"], ["\n  list-style: none;\n  margin: 0;\n  padding: 0;\n\n  li {\n    display: flex;\n    align-items: center;\n    justify-content: ", ";\n    gap: 12px;\n    padding: 12px;\n    border-bottom: 1px solid ", ";\n\n    svg {\n      width: 22px;\n      fill: ", ";\n    }\n\n    &:last-child {\n      border-bottom-width: 0;\n    }\n  }\n"])), function (props) {
    return props.justifyContent ? props.justifyContent : 'flex-start';
}, function (props) { return props.theme.border.color; }, function (props) { return props.theme.body.textMuted; });
export function Lists(_a) {
    var items = _a.items, justifyContent = _a.justifyContent;
    if (!items)
        return;
    return (React.createElement(ListWrapper, { justifyContent: justifyContent }, items.map(function (item) { return (React.createElement("li", { key: item.key || (item.text.length > 0 && item.text.replace(' ', '')) },
        item.icon ? item.icon : '',
        " ",
        React.createElement("span", null, item.text))); })));
}
var templateObject_1;
