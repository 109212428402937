import { getDefaultStore } from 'jotai';
import jwt_decode from 'jwt-decode';
import { authAtom, idParamsAtom } from '../stores';
var store = getDefaultStore();
var query = new URLSearchParams(location.search);
export function setCookie(name, payload) {
    var id = query ? query.get('id') : store.get(idParamsAtom);
    var getAuth = store.get(authAtom);
    var decodedToken = jwt_decode(getAuth.token);
    if (store.get(idParamsAtom) === '' || store.get(idParamsAtom) !== id) {
        store.set(idParamsAtom, decodedToken.sub);
    }
    console.log(decodedToken.exp);
    var expirationDate = new Date(decodedToken.exp * 1000);
    var value = JSON.stringify(payload);
    document.cookie = "".concat(name).concat(decodedToken.sub, "=").concat(encodeURIComponent(value), "; expires=").concat(expirationDate.toUTCString(), "; path=/;");
}
export function getCookie(name) {
    var id = query ? query.get('id') : store.get(idParamsAtom);
    if (!id)
        return null;
    var cookieName = "".concat(name).concat(id);
    var cookies = document.cookie.split('; ');
    for (var _i = 0, cookies_1 = cookies; _i < cookies_1.length; _i++) {
        var cookie = cookies_1[_i];
        var _a = cookie.split('='), name_1 = _a[0], value = _a[1];
        if (name_1 === cookieName) {
            return decodeURIComponent(value);
        }
    }
    return null;
}
export function deleteAuthCookie(name) {
    var id = query ? query.get('id') : store.get(idParamsAtom);
    var cookieName = "".concat(name).concat(id);
    document.cookie = "".concat(cookieName, "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/");
}
