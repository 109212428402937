import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useAtom } from 'jotai';
import { profileAtom } from '../../stores';
import { Constants } from '../../utils/Constants';
import { getImageByName } from '../../utils/Helpers';
import { onLogout } from '../../utils/authAction';
import { CreditCardOutlineIcon, HomeOutlineIcon, PersonOutlineIcon, HelpOutlineIcon, LogoutOutlineIcon, } from '../../components/icons';
import { Paragraph } from '../../components/elements';
import { Loading } from '../../components/loading';
import { Navigation, SidebarWrapper } from './styles';
export function Sidebar() {
    var logo = Constants.WHOLESALER === 'OE'
        ? getImageByName('logo-white.png')
        : getImageByName('logo.png');
    var _a = useAtom(profileAtom), profile = _a[0], _ = _a[1];
    var _b = React.useState(false), loading = _b[0], setLoading = _b[1];
    var onClickLogout = function (e) {
        if (e && e.preventDefault)
            e.preventDefault();
        setLoading(true);
        onLogout();
    };
    return (React.createElement(React.Fragment, null,
        loading && React.createElement(Loading, { text: "Logging out..", iconWidth: "100px" }),
        React.createElement(SidebarWrapper, null,
            React.createElement("img", { className: "logo", src: logo, alt: Constants.WHOLESALERSETTINGS.name }),
            React.createElement(Navigation, null,
                React.createElement("div", { className: "link-container" },
                    React.createElement(Paragraph, { className: "user-details" }, profile
                        ? "".concat(profile.firstName || '', " ").concat(profile.lastName || '')
                        : ''),
                    React.createElement(NavLink, { to: "/home", activeClassName: "is-active" },
                        React.createElement(HomeOutlineIcon, null),
                        React.createElement("span", null, "Home")),
                    React.createElement(NavLink, { to: "/profile", activeClassName: "is-active" },
                        React.createElement(PersonOutlineIcon, null),
                        React.createElement("span", null, "Profile")),
                    React.createElement(NavLink, { to: "/billing", activeClassName: "is-active" },
                        React.createElement(CreditCardOutlineIcon, null),
                        React.createElement("span", null, "Billing")),
                    React.createElement(NavLink, { to: "/support", activeClassName: "is-active" },
                        React.createElement(HelpOutlineIcon, null),
                        React.createElement("span", null, "Support"))),
                React.createElement("a", { className: "logout", href: "#", onClick: onClickLogout },
                    React.createElement(LogoutOutlineIcon, null),
                    Constants.WHOLESALER === 'OE' ? 'Back to My Account' : 'Logout')))));
}
