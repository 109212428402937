import { __makeTemplateObject } from "tslib";
import * as React from 'react';
import styled from 'styled-components';
import { LightenDarkenColor } from '../../utils/Helpers';
import { ErrorOutlineIcon, InfoOutlineIcon, SuccessOutlineIcon, WarningOutlineIcon, } from '../../components/icons';
var bgColor = {
    success: '#edf7ed',
    info: '#e8f4fd',
    warning: '#fff4e5',
    danger: '#fdecea',
    extreme: '#dc3545',
};
var AlertWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  color: ", ";\n  border-radius: ", ";\n  padding: 10px 12px;\n  margin: 12px 0;\n  font-size: 0.825rem;\n  text-align: left;\n  display: flex;\n\n  p {\n    margin: 0 0 0 4px;\n  }\n\n  strong {\n    font-weight: 700;\n  }\n\n  svg {\n    width: 22px;\n    fill: ", ";\n    display: inline-block;\n    vertical-align: middle;\n    margin-right: 2px;\n    margin-top: -1px;\n  }\n"], ["\n  background-color: ", ";\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  color: ", ";\n  border-radius: ", ";\n  padding: 10px 12px;\n  margin: 12px 0;\n  font-size: 0.825rem;\n  text-align: left;\n  display: flex;\n\n  p {\n    margin: 0 0 0 4px;\n  }\n\n  strong {\n    font-weight: 700;\n  }\n\n  svg {\n    width: 22px;\n    fill: ", ";\n    display: inline-block;\n    vertical-align: middle;\n    margin-right: 2px;\n    margin-top: -1px;\n  }\n"])), function (props) {
    return props.type ? bgColor[props.type] : bgColor['success'];
}, function (props) {
    return props.type
        ? LightenDarkenColor(bgColor[props.type], -10)
        : LightenDarkenColor(bgColor['success'], -10);
}, function (props) {
    return props.type === 'extreme'
        ? '#ffffff'
        : props.theme.body.textColor || '#212b36';
}, function (props) { return props.theme.border.radius || '6px'; }, function (props) {
    return props.type ? props.theme[props.type] : props.theme['success'];
});
var icons = {
    success: React.createElement(SuccessOutlineIcon, null),
    info: React.createElement(InfoOutlineIcon, null),
    danger: React.createElement(ErrorOutlineIcon, null),
    warning: React.createElement(WarningOutlineIcon, null),
};
export function Alert(props) {
    return (React.createElement(AlertWrapper, { type: props.type || 'success' },
        props.icon && icons[props.type],
        React.createElement("p", null,
            props.title && React.createElement("strong", null, props.title),
            " ",
            props.message)));
}
var templateObject_1;
