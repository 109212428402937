import { __assign } from "tslib";
import * as React from 'react';
import { Loading } from '../components/loading';
export var lazyLoad = function (importFunc, selectorFunc, opts) {
    if (opts === void 0) { opts = { fallback: React.createElement(Loading, null) }; }
    var lazyFactory = importFunc;
    if (selectorFunc) {
        lazyFactory = function () {
            return importFunc().then(function (module) { return ({ default: selectorFunc(module) }); });
        };
    }
    var LazyComponent = React.lazy(lazyFactory);
    return function (props) { return (React.createElement(React.Suspense, { fallback: opts.fallback },
        React.createElement(LazyComponent, __assign({}, props)))); };
};
