import { __assign } from "tslib";
import { createJSONStorage } from 'jotai/utils';
import { atom } from 'jotai';
import { getCookie, setCookie } from '../utils/cookieAction';
export var mfaInitialValue = {
    show: false,
    verified: false,
    type: '',
    contextID: '',
    cb: null,
    cbIsPath: false,
};
var storage = createJSONStorage(function () { return sessionStorage; });
var storedValue = JSON.parse(getCookie('anx_oss_mfv_'));
var mfaValue = __assign({}, mfaInitialValue);
if (storedValue) {
    mfaValue = storedValue;
}
export var mfaAtom = atom(mfaValue);
export var handleMfa = atom(function (get) { return get(mfaAtom); }, function (get, set, payload) {
    set(mfaAtom, payload);
    setCookie('anx_oss_mfv_', payload);
});
