import { __makeTemplateObject } from "tslib";
import styled, { css } from 'styled-components';
import { media } from '../../utils/GlobalStyle';
export var baseStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #fff;\n  color: ", ";\n  padding: 8px;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  outline: 0;\n\n  &::-webkit-calendar-picker-indicator,\n  &::-moz-calendar-picker-indicator {\n    position: absolute;\n    left: 5%;\n  }\n\n  &:hover {\n    border-color: ", ";\n  }\n  &:focus {\n    border-color: ", ";\n  }\n"], ["\n  background-color: #fff;\n  color: ", ";\n  padding: 8px;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  outline: 0;\n\n  &::-webkit-calendar-picker-indicator,\n  &::-moz-calendar-picker-indicator {\n    position: absolute;\n    left: 5%;\n  }\n\n  &:hover {\n    border-color: ", ";\n  }\n  &:focus {\n    border-color: ", ";\n  }\n"])), function (props) { return props.theme.body.textColor; }, function (props) { return props.theme.border.radius || '6px'; }, function (props) { return props.theme.border.color || '#bdbdbd'; }, function (props) { return props.theme.border.hover || '#757575'; }, function (props) { return props.theme.border.focus || '#424242'; });
var errorStyle = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-color: ", ";\n"], ["\n  border-color: ", ";\n"])), function (props) { return props.theme.border.error || '#d32f2f'; });
var blockStyle = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n"], ["\n  display: block;\n  width: 100%;\n"])));
var layouts = {
    vertical: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    fieldset {\n      display: grid;\n      grid-template-columns: 1fr;\n      place-items: start stretch;\n\n      input:not([type='checkbox']),\n      select,\n      textarea {\n        display: block;\n        width: 100%;\n      }\n\n      ", " {\n        grid-template-columns: minmax(150px, 200px) 1fr;\n\n        label {\n          padding-top: 4px;\n        }\n      }\n    }\n  "], ["\n    fieldset {\n      display: grid;\n      grid-template-columns: 1fr;\n      place-items: start stretch;\n\n      input:not([type='checkbox']),\n      select,\n      textarea {\n        display: block;\n        width: 100%;\n      }\n\n      ", " {\n        grid-template-columns: minmax(150px, 200px) 1fr;\n\n        label {\n          padding-top: 4px;\n        }\n      }\n    }\n  "])), media.medium),
    horizontal: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    fieldset {\n      label {\n        display: block;\n        margin-bottom: 4px;\n      }\n      input,\n      select,\n      textarea {\n        display: block;\n        width: 100%;\n      }\n    }\n  "], ["\n    fieldset {\n      label {\n        display: block;\n        margin-bottom: 4px;\n      }\n      input,\n      select,\n      textarea {\n        display: block;\n        width: 100%;\n      }\n    }\n  "]))),
};
export var Form = styled.form(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 12px 0;\n  ", ";\n"], ["\n  margin: 12px 0;\n  ", ";\n"])), function (props) { return (props.layout ? layouts[props.layout] : ''); });
export var Input = styled.input(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n  ", "\n  ", "\n"], ["\n  ", ";\n  ", "\n  ", "\n"])), baseStyle, function (props) { return (props.block ? blockStyle : ''); }, function (props) { return (props.error ? errorStyle : ''); });
export var Select = styled.select(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", ";\n  ", "\n  ", "\n"], ["\n  ", ";\n  ", "\n  ", "\n"])), baseStyle, function (props) { return (props.block ? blockStyle : ''); }, function (props) { return (props.error ? errorStyle : ''); });
export var Textarea = styled.textarea(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  resize: none;\n  ", ";\n  ", "\n  ", "\n  ", "\n"], ["\n  resize: none;\n  ", ";\n  ", "\n  ", "\n  ", "\n"])), baseStyle, function (props) {
    return props.minHeight
        ? css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n          min-height: ", ";\n        "], ["\n          min-height: ", ";\n        "])), props.minHeight) : css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n          min-height: 200px;\n        "], ["\n          min-height: 200px;\n        "])));
}, function (props) { return (props.block ? blockStyle : ''); }, function (props) { return (props.error ? errorStyle : ''); });
export var Label = styled.label(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: 0.9em;\n  font-weight: 600;\n  line-height: 1.3;\n  color: ", ";\n"], ["\n  font-size: 0.9em;\n  font-weight: 600;\n  line-height: 1.3;\n  color: ", ";\n"])), function (props) { return props.theme.body.textMuted; });
export var FormFields = styled.fieldset(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: block;\n  border: 0;\n  margin: 0 0 16px;\n  padding: 0;\n"], ["\n  display: block;\n  border: 0;\n  margin: 0 0 16px;\n  padding: 0;\n"])));
export var ErrorMsg = styled.i(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: block;\n  padding: 2px 0;\n  color: ", ";\n  font-size: 0.85em;\n  text-align: left;\n  font-style: italic;\n"], ["\n  display: block;\n  padding: 2px 0;\n  color: ", ";\n  font-size: 0.85em;\n  text-align: left;\n  font-style: italic;\n"])), function (props) { return props.theme.border.error || '#d32f2f'; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
