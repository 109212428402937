import { __assign } from "tslib";
import { useAtom } from 'jotai';
import * as React from 'react';
import { useLocation, useHistory } from 'react-router';
import { idParamsAtom } from '../stores';
var withIdParams = function (WrappedComponent) {
    return function (props) {
        var location = useLocation();
        var history = useHistory();
        var query = new URLSearchParams(location.search);
        var idParam = query.get('id');
        var atomIdParams = useAtom(idParamsAtom)[0];
        if (!idParam) {
            query.set('id', atomIdParams || '');
            var newPath = "".concat(location.pathname, "?").concat(query.toString());
            history.replace(newPath);
        }
        return React.createElement(WrappedComponent, __assign({}, props));
    };
};
export default withIdParams;
