import jwt_decode from 'jwt-decode';
import { getCookie } from './cookieAction';
var TokenManager = (function () {
    function TokenManager() {
    }
    TokenManager.validateToken = function (token) {
        if (typeof token !== 'undefined' && token != null && token.length > 0) {
            try {
                var decodedToken = jwt_decode(token);
                if (decodedToken.exp < Date.now() / 1000) {
                    return false;
                }
                return true;
            }
            catch (e) {
                return false;
            }
        }
        return false;
    };
    TokenManager.clearToken = function () {
        sessionStorage.removeItem('anx_oss_a');
    };
    TokenManager.getToken = function () {
        var getAuthToken = getCookie('anx_oss_a_');
        var parsedAuthData = getAuthToken ? JSON.parse(getAuthToken) : '';
        return parsedAuthData ? parsedAuthData.token : '';
    };
    TokenManager.getDecodedToken = function () {
        if (this.getToken() !== null) {
            return jwt_decode(this.getToken());
        }
    };
    TokenManager.getTokenHeader = function () {
        return {
            headers: {
                'X-Access-Token': this.getToken(),
            },
        };
    };
    return TokenManager;
}());
export { TokenManager };
