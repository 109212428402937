import * as React from 'react';
import { PageWrapper, Main } from '../../components/elements';
import { Sidebar } from '../../components/sidebar';
import { Header } from '../../components/header';
import MfaCode from '../../components/mfa';
export function AuthenticatedRoot(_a) {
    var children = _a.children;
    return (React.createElement(Main, null,
        React.createElement(Sidebar, null),
        React.createElement(PageWrapper, null,
            React.createElement(Header, null),
            children),
        React.createElement(MfaCode, null)));
}
