import { __awaiter, __generator } from "tslib";
import { atom } from 'jotai';
import { authAtom } from './authentication';
import { getUserDetails } from './apiCalls';
export var profileAtom = atom({});
export var handleProfileAtom = atom(function (get) { return get(profileAtom); }, function (get, set) { return __awaiter(void 0, void 0, void 0, function () {
    var newProfile, auth, resp;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                newProfile = get(profileAtom);
                auth = get(authAtom);
                return [4, getUserDetails(auth.token)];
            case 1:
                resp = _a.sent();
                if (resp) {
                    newProfile = resp;
                }
                set(profileAtom, newProfile);
                return [2];
        }
    });
}); });
