import { __assign, __rest } from "tslib";
import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authAtom } from '../stores';
import { AuthenticatedRoot } from './Root/AuthenticatedRoot';
export function PrivateRoute(_a) {
    var Component = _a.component, rest = __rest(_a, ["component"]);
    var auth = useAtom(authAtom)[0];
    return (React.createElement(Route, __assign({}, rest, { render: function (props) {
            return auth && auth.isAuthenticated ? (React.createElement(AuthenticatedRoot, null,
                React.createElement(Component, __assign({}, props)))) : (React.createElement(Redirect, { to: { pathname: '/login', state: { from: props.location } } }));
        } })));
}
