import { __assign, __makeTemplateObject, __rest } from "tslib";
import * as React from 'react';
import styled from 'styled-components';
var CardWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  position: relative;\n  background-color: #fff;\n  margin-bottom: 24px;\n  border-radius: ", ";\n  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),\n    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);\n  font-size: 0.95rem;\n  overflow: hidden;\n\n  .card-header {\n    font-size: 1.125em;\n    font-weight: 600;\n    padding: 12px 18px;\n    border-bottom: 1px solid ", ";\n  }\n\n  .card-footer {\n    padding: 12px 18px;\n    border-top: 1px solid ", ";\n    border-bottom-left-radius: ", ";\n    border-bottom-right-radius: ", ";\n    transition: all 0.2s ease-in;\n\n    &.clickable:hover {\n      cursor: pointer;\n\n      &:hover {\n        background-color: ", ";\n      }\n    }\n  }\n"], ["\n  width: 100%;\n  position: relative;\n  background-color: #fff;\n  margin-bottom: 24px;\n  border-radius: ", ";\n  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),\n    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);\n  font-size: 0.95rem;\n  overflow: hidden;\n\n  .card-header {\n    font-size: 1.125em;\n    font-weight: 600;\n    padding: 12px 18px;\n    border-bottom: 1px solid ", ";\n  }\n\n  .card-footer {\n    padding: 12px 18px;\n    border-top: 1px solid ", ";\n    border-bottom-left-radius: ", ";\n    border-bottom-right-radius: ", ";\n    transition: all 0.2s ease-in;\n\n    &.clickable:hover {\n      cursor: pointer;\n\n      &:hover {\n        background-color: ", ";\n      }\n    }\n  }\n"])), function (props) { return props.theme.border.radius || '6px'; }, function (props) { return props.theme.border.color; }, function (props) { return props.theme.border.color; }, function (props) { return props.theme.border.radius || '6px'; }, function (props) { return props.theme.border.radius || '6px'; }, function (props) { return props.theme.button.info.hover; });
var CardContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), function (props) { return (props.padding ? props.padding : '18px'); });
export function Card(_a) {
    var children = _a.children, heading = _a.heading, footer = _a.footer, footerClickable = _a.footerClickable, padding = _a.padding, rest = __rest(_a, ["children", "heading", "footer", "footerClickable", "padding"]);
    return (React.createElement(CardWrapper, __assign({}, rest),
        heading && React.createElement("div", { className: "card-header" }, heading),
        React.createElement(CardContent, { padding: padding || '18px' }, children),
        footer ? (React.createElement("div", { className: "card-footer".concat(footerClickable ? ' clickable' : '') }, footer)) : ('')));
}
var templateObject_1, templateObject_2;
