import * as React from 'react';
import { Constants } from '../../utils';
import { ArrowBackIosOutlineIcon, HomeOutlineIcon } from '../icons';
import { Navigation } from './style';
export default function TopNav(_a) {
    var onBack = _a.onBack, onClickHome = _a.onClickHome, logo = _a.logo;
    return (React.createElement(React.Fragment, null,
        React.createElement(Navigation, null,
            React.createElement("button", { type: "button", onClick: onBack },
                React.createElement(ArrowBackIosOutlineIcon, { fill: "#fff" })),
            React.createElement("button", { type: "button", onClick: onClickHome },
                React.createElement(HomeOutlineIcon, { fill: "#fff" }))),
        React.createElement("img", { className: "logo", src: logo, alt: Constants.WHOLESALERSETTINGS.name, width: "100px" })));
}
